.title_box {
  text-align: center;
}

.title_box h2 {
  font-size: 2.4rem;
  font-weight: 600;
}

.title_box p {
  font-size: 2rem;
  padding: 10px 0 30px;
}

.news_list li:last-child:after {
  display: none;
}

.news_badge:hover {
  border: 1px solid transparent;
  background: linear-gradient(#fff, #fff),
    linear-gradient(90deg, #4c54ff 41.27%, #4c94ff 92.02%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.news_box .news_badge:hover span {
  color: var(--medium_blue1);
}

.news_badge.badge_off {
  border: 1px solid #9000d3;
  color: #9000d3;
}

.data_off {
  color: #9000d3 !important;
}

.logo_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_wrap img {
  width: 180px;
  margin: 50px 10px 0;
}

.content_title h1,
.content_title h2 {
  color: #fff;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.content_title h1 {
  top: calc(50% + 40px);
}

.webinar_wrap .content_title h2 {
  font-size: 2rem;
  font-weight: 400 !important;
  padding: 0 20px;
  text-align: center;
  width: 100%;
}

.content_title h2 {
  top: calc(50% + 90px);
}

.conference_box {
  box-shadow: 0px 0px 10px rgba(165, 169, 188, 0.5);
  border-radius: 20px;
  margin-bottom: 100px;
}

.conference_box .cont1 {
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to bottom right, #8353d7, #8ed9e7);
  color: #fff;
  padding: 50px 30px;
}

.conference_box h1 {
  font-size: 4rem;
  line-height: 48px;
  text-align: center;
  margin-bottom: 15px;
}

.conference_box h3 {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin-bottom: 80px;
}

.conference_box .cont2 {
  background: #fff;
  padding: 50px 80px;
  border-radius: 0 0 20px 20px;
}

.conference_box .cont2 p.title {
  font-size: 2.4rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 30px;
}

.conference_box .cont2 p {
  font-size: 1.8rem;
  line-height: 32px;
  color: #333;
}

.conference_box .cont2 .mt_minus {
  margin-top: -90px;
}

.conference_box .cont2 .d-flex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.conference_box .cont2 .d-flex .d-column {
  flex-direction: column;
  margin-right: 90px;
  text-align: center;
}

.conference_box .cont2 .d-flex .d-column:last-child {
  margin-right: 0;
}

.conference_box .cont2 .d-flex .d-column p {
  padding-top: 20px;
  line-height: 26px;
  text-align: center;
}

.conference_box .url_link {
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 8px 18px;
  font-size: 1.6rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conference_box .url_link img {
  margin-right: 6px;
}

.webinar_wrap .news_first {
  width: 98%;
}

.webinar_wrap .news_first .news_img {
  width: 400px;
}

.webinar_wrap .news_title {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600 !important;
  line-height: 28px;
  color: #000;
  margin: 10px 0;
}

.webinar_wrap .news_content {
  word-break: break-all;
  font-size: 1.5rem;
  line-height: 24px;
  color: #000;
}

.webinar_wrap .d-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #666 !important;
  margin-top: 48px;
}

.webinar_wrap .news_data {
  padding-left: 10px;
}

.news_first:hover .news_data {
  color: #fff !important;
}

.news_data {
  font-size: 1.4rem;
  color: #0557f5;
}

/* contents */
.contents_wrap .news_box .news_content {
  width: 100%;
}

.contents_wrap .news_box {
  padding: 40px 150px 65px;
  display: flex;
  align-items: center;
}

.contents_wrap .news_badge {
  margin-bottom: 30px;
}

.contents_wrap .news_content img {
  width: 100%;
}

.contents_wrap .news_content {
  margin: 0;
}

.contents_wrap .news_box .news_content .text_box {
  padding: 40px 0;
}

.contents_wrap .news_box .news_content p {
  font-size: 1.8rem;
  line-height: 32px;
  color: #333;
}

.news_box .news_content p {
  padding-bottom: 0;
}

.contents_wrap .btn_webinar {
  width: 100%;
  border-radius: 5px;
  padding: 12px;
  background: #0557f5;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.contents_wrap .news_btn {
  display: flex;
  margin-top: 40px;
  border-top: 1px solid var(--achromatic10);
  padding-top: 30px;
  width: 100%;
  justify-content: center;
}

.contents_wrap .text_box span {
  display: block;
  font-size: 2rem;
  color: #0557f5;
  padding-bottom: 16px;
}

.contents_wrap .text_box table {
  line-height: normal;
  padding-left: 10px;
  padding-bottom: 16px;
  font-size: 1.8rem;
}

.contents_wrap .text_box table th,
.contents_wrap .text_box table td {
  padding: 8px 0;
  white-space: break-spaces;
}

.contents_wrap .text_box table th {
  width: 80px;
  text-align: left;
}

@media screen and (max-width: 1280px) {
  .conference_box .cont2 .mt_minus {
    margin-top: -70px;
  }
}

@media screen and (max-width: 1200px) {
  .news_first {
    display: flex;
  }

  .conference_box .cont2 .d-flex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .conference_box .cont2 .d-flex .d-column {
    margin: 0 auto 30px;
  }

  .conference_box .cont2 .d-flex .d-column:last-child {
    margin: auto;
  }

  .conference_box .cont2 .mt_minus {
    margin-top: -60px;
  }
}

@media screen and (max-width: 1024px) {
  .webinar_wrap .news_first .news_img {
    width: 360px;
    height: 200px;
  }

  .news_first {
    height: 200px;
  }

  .contents_wrap .news_box {
    padding: 40px 100px 65px;
  }

  .conference_box .cont2 .mt_minus {
    margin-top: -40px;
  }
}

@media screen and (max-width: 820px) {
  .webinar_wrap .news_first .news_img {
    width: 300px;
    height: 168px;
  }

  .conference_box {
    margin-bottom: 80px;
  }

  .news_first {
    height: 168px;
  }

  .news_thumbnail {
    padding: 18px 20px;
  }

  .news_badge {
    margin-bottom: 10px;
  }

  .webinar_wrap .news_title {
    margin: 8px 0;
    line-height: 20px;
  }

  .webinar_wrap .news_content {
    line-height: 20px;
  }

  .conference_box .cont2 .mt_minus {
    margin-top: -30px;
  }
}

@media screen and (max-width: 768px) {
  .conference_box .cont2 {
    padding: 48px 50px 52px;
  }

  .conference_box .cont2 .d-flex .d-column img {
    width: 100px;
  }

  .news_first {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
  }

  .webinar_wrap .news_first .news_img {
    width: 100%;
    height: auto;
  }

  .news_thumbnail {
    padding: 34px 38px;
  }

  .contents_wrap .news_box {
    padding: 40px 80px 65px;
  }

  .conference_box .cont2 .mt_minus {
    margin-top: -20px;
  }
}

@media screen and (max-width: 480px) {
  .content_bg {
    margin-top: 30px;
  }

  .conference_box .cont2 {
    padding: 48px 30px 52px;
  }

  .conference_box {
    margin-bottom: 50px;
  }

  .conference_box .cont2 .d-flex .d-column img {
    width: 80px;
  }

  .news_thumbnail {
    padding: 24px 28px;
  }

  .contents_wrap .news_box {
    padding: 40px 30px 65px;
  }

  .contents_wrap .text_box table th {
    width: 60px;
    text-align: left;
  }

  .logo_wrap img {
    width: 50%;
  }
}
