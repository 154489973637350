.slogan_wrap .container {
  position: relative;
  padding: 10vw 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: keep-all;
}

.slogan_wrap .heading3 {
  color: var(--medium_blue1);
}

.slogan_wrap .display2 {
  max-width: 750px;
  padding: 2vw 20px;
  margin: 0 auto;
}

.slogan_wrap .sub_title4 {
  padding: 0 20px;
  color: var(--achromatic5);
}

.main_banner_icon1 {
  position: absolute;
  top: 90px;
  left: 240px;
  animation: bounce1 2.2s 0.3s linear infinite;
}

@keyframes bounce1 {
  0% {
    top: 90px;
  }
  50% {
    top: 80px;
  }
  100% {
    top: 90px;
  }
}

.main_banner_icon2 {
  position: absolute;
  top: 200px;
  left: 20px;
  animation: bounce2 2.2s linear infinite;
}

@keyframes bounce2 {
  0% {
    top: 200px;
  }
  50% {
    top: 210px;
  }
  100% {
    top: 200px;
  }
}

.main_banner_icon3 {
  position: absolute;
  bottom: 18vw;
  left: 100px;
  animation: bounce3 2.2s 0.7s linear infinite;
}

@keyframes bounce3 {
  0% {
    bottom: 18vw;
  }
  50% {
    bottom: 17.5vw;
  }
  100% {
    bottom: 18vw;
  }
}

.main_banner_icon6 {
  position: absolute;
  top: 90px;
  right: 180px;
  animation: bounce6 2.2s 0.6s linear infinite;
}

@keyframes bounce6 {
  0% {
    top: 90px;
  }
  50% {
    top: 80px;
  }
  100% {
    top: 90px;
  }
}

.main_banner_icon5 {
  position: absolute;
  top: 200px;
  right: 20px;
  animation: bounce5 2.2s linear infinite;
}

@keyframes bounce5 {
  0% {
    top: 200px;
  }
  50% {
    top: 210px;
  }
  100% {
    top: 200px;
  }
}

.main_banner_icon4 {
  position: absolute;
  bottom: 18vw;
  right: 50px;
  animation: bounce4 2.2s 0.4s linear infinite;
}

@keyframes bounce4 {
  0% {
    bottom: 18vw;
  }
  50% {
    bottom: 17.5vw;
  }
  100% {
    bottom: 18vw;
  }
}

.slogan_link {
  padding-top: 60px;
}

@media screen and (max-width: 1280px) {
  .main_banner_icon1 {
    position: absolute;
    top: 3vw;
    left: 18%;
    animation: none;
  }
  .main_banner_icon2 {
    width: 90px;
    position: absolute;
    top: 3vw;
    left: 50%;
    transform: translateX(-50%);
    animation: none;
  }
  .main_banner_icon3 {
    position: absolute;
    top: 3vw;
    left: auto;
    right: 18%;
    animation: none;
  }
  .main_banner_icon4 {
    width: 110px;
    position: absolute;
    top: auto;
    bottom: 3vw;
    left: 18%;
    animation: none;
  }
  .main_banner_icon5 {
    position: absolute;
    top: auto;
    bottom: 3vw;
    left: 50%;
    transform: translateX(-50%);
    animation: none;
  }
  .main_banner_icon6 {
    position: absolute;
    top: auto;
    bottom: 4vw;
    right: 18%;
    animation: none;
  }
}

@media screen and (max-width: 1023px) {
  .slogan_wrap .container {
    padding: 14vw 0;
  }
}

@media screen and (max-width: 768px) {
  .slogan_wrap {
    margin: 30px 0;
  }
  .slogan_wrap .container {
    padding: 18vw 0;
  }
  .slogan_wrap .sub_title4 {
    width: 60%;
    margin: 0 auto;
  }
  .main_banner_icon1 {
    width: 60px;
    left: 12%;
  }
  .main_banner_icon2 {
    width: 80px;
  }
  .main_banner_icon3 {
    width: 70px;
    right: 12%;
  }
  .main_banner_icon4 {
    width: 90px;
    left: 12%;
  }
  .main_banner_icon5 {
    width: 70px;
  }
  .main_banner_icon6 {
    width: 100px;
    right: 12%;
  }
}

@media screen and (max-width: 480px) {
  .slogan_wrap .container {
    padding: 30vw 0;
  }
  .slogan_wrap .display2 {
    padding: 20px 0;
  }
  .slogan_wrap .sub_title4 {
    width: 70%;
    margin: 0 auto;
  }
  .main_banner_icon1 {
    width: 45px;
    top: 6vw;
  }
  .main_banner_icon2 {
    width: 65px;
    top: 6vw;
  }
  .main_banner_icon3 {
    width: 55px;
    top: 6vw;
  }
  .main_banner_icon4 {
    width: 75px;
    bottom: 6vw;
  }
  .main_banner_icon5 {
    width: 55px;
    bottom: 6vw;
  }
  .main_banner_icon6 {
    width: 85px;
    bottom: 7vw;
  }
}

@media screen and (max-width: 414px) {
  .slogan_wrap .sub_title4 {
    width: 76%;
    margin: 0 auto;
  }
}
