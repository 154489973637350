.partners_wrap .content_title .heading1 {
  top: 72%;
}

.partners_wrap .content_bg {
  padding: 50px 0;
}

.partners_box {
  max-width: 1280px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(165, 169, 188, 0.5);
  border-radius: 20px;
}

.partners_cont {
  max-width: 1200px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.partners_cont img {
  max-width: 100%;
}

.partners_wrap .route li:nth-child(2)::after {
  display: none;
}

.voucher_wrap .route ul li:nth-child(2)::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #d2d4de;
  border-right: 2px solid #d2d4de;
  transform: rotate(45deg);
}