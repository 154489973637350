.modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.modal_container {
  position: relative;
  width: 600px;
  background-color: #fff;
  border-radius: 10px;
}

.modal_close {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 10px;
}

.modal_close img {
  width: 24px;
  height: 24px;
}

.modal_header {
  padding: 34px;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(128.41deg, #8357d7 20.12%, #8dd5e6 89.74%);
  color: #fff;
  text-align: center;
}

.modal_header h2 {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 10px;
}

.modal_header p {
  font-size: 30px;
  font-weight: 700;
}

.modal_title h1 {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 18px;
}

.modal_title p {
  font-size: 18px;
}

.modal_box {
  padding: 44px 72px 80px;
}

.modal_box p {
  font-size: 16px;
  padding-bottom: 10px;
}

.modal_box p span {
  color: #ff0000;
}

.modal_box input {
  margin-bottom: 28px;
  border: 1px solid #999;
  border-radius: 5px;
  height: 44px;
  padding: 10px;
}

.modal_box .d-flex {
  margin-top: 6px;
  border-top: 1px solid #999;
  padding-top: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  cursor: pointer;
}

.modal_box .d-flex input {
  height: auto;
  margin-right: 8px;
  margin-bottom: 0;
}

.modal_box .d-flex p {
  padding-bottom: 0;
  color: #666;
}

.sub_btn {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #010f4e;
  color: #fff;
  font-size: 16px;
}

.sub_btn_news {
  display: block;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #010f4e;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
}

/* 개인정보수집이용 */
.modal_agree_box {
  position: relative;
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  font-size: 16px;
  cursor: auto;
}

.modal_agree_box h1 {
  padding-bottom: 10px;
  font-size: 20px;
}

.modal_agree_box p {
  color: #000 !important;
}

.modal_agree_box .d-end {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal_agree_box .close {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  padding: 10px 20px;
}

@media screen and (max-width: 430px) {
  .modal_wrap.subscribe {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .modal_wrap.subscribe .modal_container,
  .modal_wrap.subscribe .modal_header {
    border-radius: 0;
  }

  .modal_wrap.subscribe .modal_box {
    padding: 44px 50px 80px;
  }
}
