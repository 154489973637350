.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1rem;
  background: #e6eefe; */
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  margin: 0 2px;
}

/* ul.pagination li:hover {
  transition: all 0.5s;
  background-color: #e6e6e6;
} */

ul.pagination li:nth-child(1),
ul.pagination li:nth-child(2),
ul.pagination li:nth-child(8),
ul.pagination li:nth-child(9) {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

ul.pagination li:nth-child(1) a,
ul.pagination li:nth-child(2) a,
ul.pagination li:nth-child(8) a,
ul.pagination li:nth-child(9) a {
  color: #bbbfcd;
  font-size: 16px;
}

ul.pagination li a {
  text-decoration: none;
  /* color: #000; */
  color: var(--black);
  font-size: 14px;
}

ul.pagination li.active a {
  color: #fff;
}

ul.pagination li.active {
  background: #0557f5;
  border-radius: 5px;
  border: none;
}
