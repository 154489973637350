.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wrap {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-color: #f4f9ff;
}

.header {
  width: 100%;
  height: 80px;
  padding: 25px 0;
  background-color: #fff;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expo_container {
  width: 1290px;
  margin: 0 auto;
}

.header h1 {
  font-size: 20px;
}

.header .contents1 {
  font-size: 16px;
  padding-top: 4px;
}

.header .contents1 span {
  margin: 0 18px;
}

.ai_wrap {
  margin-top: 160px;
}

.ai_box {
  width: 400px;
  margin: 0 22px;
  padding: 52px 45px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 23.1px 0px #0000001a;
}

.ai_box img.ai_img {
  width: 100%;
  height: 200px;
  border-radius: 5px;
}

.ai_box .sub_title2 {
  font-size: 22px;
  font-weight: 600 !important;
  color: #0015d2;
  margin: 32px 0 24px;
}

.ai_box .contents2 {
  font-size: 17px;
  line-height: 26px;
  color: #2d2d2d;
}

.ai_btn {
  margin-top: 50px;
  justify-content: space-between;
  border-radius: 5px;
  padding: 18px 20px;
  background: linear-gradient(90deg, #4c54ff 0%, #4c94ff 100%);
  color: #fff;
  font-size: 18px;
  font-weight: 500 !important;
}

/* 내용 */
.main_title {
  margin-top: 30px;
}

.heading2 {
  font-size: 22px;
  font-weight: 600 !important;
  color: #0015d2;
}

.main_title .contents1 {
  font-size: 20px;
  font-weight: 500;
  color: #3c3c3c;
  padding-left: 16px;
}

.iframe_wrap {
  margin: 30px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_box {
  position: relative;
  width: 300px;
  margin-right: 30px;
  border-radius: 5px;
  padding: 25px 20px 20px;
  background: #fff;
  text-align: center;
}

.check_icon {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
}

.num_wrap {
  position: relative;
}

.num01 {
  position: absolute;
  top: -10px;
  left: 20px;
}

.num02 {
  position: absolute;
  top: -10px;
  left: 340px;
}

.num03 {
  position: absolute;
  top: -10px;
  right: 590px;
}

.num04 {
  position: absolute;
  top: -10px;
  right: 270px;
}

.sub_wrap .sub_title4 {
  font-size: 16px;
  font-weight: 600 !important;
}

.sub_wrap .contents2 {
  font-size: 14px;
}

.sub_box:last-child {
  margin-right: 0;
}

.sub_icon {
  height: 50px;
  margin: 10px 0;
}

.workflow_next {
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
  height: 30px;
}

.ai_list_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.ai_list_btn .btn {
  position: relative;
  background-color: #86b5e9;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  padding: 15px 20px;
}

.ai_list_btn .btn img {
  height: 16px;
}

.ai_list_btn .btn .prev_icon {
  transform: scaleX(-1);
  padding-left: 40px;
}

.ai_list_btn .btn .next_icon {
  padding-left: 40px;
}
