.visual_container {
  overflow: hidden;
}

.visual_slide {
  overflow: hidden;
  height: 650px;
  background: linear-gradient(
    93.79deg,
    rgba(36, 105, 168, 0.5) 18.03%,
    rgba(116, 79, 164, 0.5) 93.11%
  );
}

.visual_slide video {
  position: relative;
  z-index: -1;
}

.visual_slide .visual_text .upstage {
  display: flex;
  justify-content: center;
}

.visual_slide .visual_text .upstage img {
  width: 500px;
  margin-bottom: 10px;
}

.visual_text {
  position: absolute;
  top: 150px;
  width: inherit;
  color: #fff;
  word-break: keep-all;
  text-align: center;
}

.visual_text p {
  padding-bottom: 10px;
}

.visual_text .button1:nth-child(2) {
  margin-left: 20px;
}

.visual_text .blue_btn {
  width: fit-content;
  justify-content: space-between;
  margin-top: 20px;
}

.visual_container .slick-dots {
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
}

.visual_container .slick-dots li button,
.visual_container .slick-dots li {
  width: 50px;
  height: 100%;
}

.visual_container .slick-dots li button:before {
  content: "";
  width: 50px;
  height: 4px;
  background: #fff;
  border-radius: 100px;
  transition: all 0.3s;
}

@media screen and (max-width: 1280px) {
  .LLM_icon_wrap {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .visual_text {
    width: 90vw;
  }
}

@media screen and (max-width: 768px) {
  .visual_text {
    top: 130px;
  }

  .heading3 {
    font-size: 2.7rem;
  }

  .visual_text .display1 {
    font-size: 4rem;
  }

  .visual_text p {
    padding-bottom: 20px;
  }

  .visual_text .blue_btn {
    margin-top: 0;
    margin-right: 10px;
    display: inline-flex;
  }

  .visual_text .button1:nth-child(2) {
    margin-left: 0px;
  }

  .visual_slide .visual_text .upstage img {
    width: 80%;
  }
}

@media screen and (max-width: 610px) {
  .visual_container .slick-dots {
    bottom: 50px;
  }
}

@media screen and (max-width: 390px) {
  .visual_text p {
    padding-bottom: 15px;
  }

  .visual_container .slick-dots li button,
  .visual_container .slick-dots li,
  .visual_container .slick-dots li button:before {
    width: 40px;
  }

  .visual_container .slick-dots {
    bottom: 30px;
  }

  .visual_text .display1 {
    font-size: 3.8rem;
  }
}
