header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  backdrop-filter: blur(7.5px);
  box-shadow: 0px 4px 15px rgba(122, 57, 57, 0.1);
  color: #fff;
  transition: all 0.2s;
  z-index: 9999;
}

header:hover {
  background: #fff;
}

header:hover a {
  color: #000;
}

nav {
  background-color: rgb(255 255 255 / 5%);
  width: 100%;
  height: 80px;
  padding: 10px !important;
  display: flex !important;
  z-index: 999;
}

header nav .container {
  width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#navigation ul li a {
  padding: 20px;
  margin: 0 40px;
  cursor: default;
}

.scrolled {
  background: #fff;
  color: #000;
}

.scrolled .logo {
  background-image: url(../assets/img/logo_white.png) !important;
  background-repeat: no-repeat !important;
  background-size: 180px !important;
}

.scrolled .nav-toggle {
  background-image: url(../assets/img/mobile_menu_open_gray.png);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: 50% 50%;
}

.logo {
  display: inline-block;
  background-image: url(../assets/img/logo_dark.png);
  background-repeat: no-repeat;
  background-size: 180px;
  width: 180px;
  height: 30px;
  margin-left: 10px;
}

header:hover .logo {
  background-image: url(../assets/img/logo_white.png);
}

.nav-toggle {
  display: block;
  position: absolute;
  right: 15px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  padding: 10px;
}

.nav-toggle img {
  width: 30px;
  height: 30px;
}

header:hover .nav-toggle {
  background-image: url(../assets/img/mobile_menu_open_gray.png);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: 50% 50%;
}

.nav_menu {
  display: flex;
  height: 80px;
  line-height: 80px;
  overflow: hidden;
  transition: all 0.2s;
}

.dropdown {
  padding: 0 60px;
  height: 80px;
  line-height: 80px;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.dropdown_menu {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: rgb(255 255 255 / 90%);
  color: #000;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.nav_menu:hover .dropdown_menu {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80px;
  left: 0;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}

.dropdown_menu_wrap {
  width: 1280px;
  display: flex;
  justify-content: flex-end;
}

.dropdown_menu_wrap div:nth-child(1) {
  margin: 10px 52px;
}

.dropdown_menu_wrap div:nth-child(2) {
  margin: 10px 40px;
}

.dropdown_menu_wrap div:nth-child(3) {
  margin: 10px 22px;
}

.dropdown_menu_wrap div:nth-child(4) {
  margin: 10px 50px;
}

.dropdown_menu a {
  display: flex;
  justify-content: center;
  padding: 10px !important;
  margin: 10px 0 !important;
  cursor: pointer !important;
}

.dropdown_menu a:hover {
  background-image: linear-gradient(to right, #4c54ff, #4c94ff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media (min-width: 1200px) {
  .nav-toggle {
    display: none;
  }
}

@media (max-width: 1200px) {
  .nav_menu {
    display: none;
  }
}

.mobile_wrap.open {
  display: block;
  z-index: 999;
  overflow-x: scroll;
}

.mobile_wrap {
  display: none;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  color: #000;
}

.mobile_header {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.mobile_logo img {
  width: 180px;
  margin-top: 2px;
  margin-left: 10px;
}

.mobile_wrap ul {
  border-top: 1px solid #ccc;
  padding: 1.6rem 3rem;
}

.mobile_wrap .sub_title2 {
  color: var(--achromatic5);
  margin-top: 4px;
}

.mobile_wrap .sub_title2 img {
  padding-right: 10px;
}

#navigation .mobile_wrap ul li .heading2 {
  font-size: 2.6rem;
  padding: 0 0 0 10px;
  margin: 0;
}

#navigation .mobile_wrap ul li .navlink {
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 10px;
  margin: 0;
}

#navigation .mobile_wrap ul li .sub_border {
  border-bottom: 1px solid #ccc;
  padding-bottom: 14px;
  margin-bottom: 1.6rem;
}

.close-button {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  padding: 10px;
}

.close-button img {
  width: 25px;
}
