.support_header {
  text-align: center;
}

.support_header p {
  margin: 10px 0 30px 0;
}

.faq_search {
  background-color: var(--medium_blue10);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 5, 121, 0.25);
}

.faq_search input {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  margin-right: 15px;
  border: none;
}

.faq_search .blue_btn {
  border-radius: 10px;
  padding: 14px 40px;
}

.faq_list {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--achromatic10);
  box-shadow: 0px 2px 30px rgba(0, 5, 121, 0.1);
  border-radius: 10px;
  margin-top: 20px;
}

.faq_title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 25px;
}

.faq_content {
  border-top: 1px solid var(--achromatic10);
  display: none;
}

.faq_content.active {
  display: block;
  background: #f6f6f6;
  padding: 30px;
}

.faq_content .contents1 {
  line-height: 1.6;
}

.dropdown_icon,
.dropup_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
}

.icon_hidden {
  display: none;
}

.active .dropdown_icon {
  display: none;
}

.active .dropup_icon {
  display: block;
}

.dropup_icon {
  transform: translateY(-50%) rotate(180deg);
}

.faq_wrap {
  margin: 100px 0;
}

.support_box {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 2px 30px rgba(0, 5, 121, 0.1);
}

.contact_list li > p {
  margin: 20px 0 5px 0;
}

.contact_list li:first-child > p {
  margin-top: 0;
}

label {
  position: relative;
  padding-left: 35px;
  margin-right: 40px;
  cursor: pointer;
  display: inline-flex;
}

label:last-child {
  margin-right: 0;
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark,
.radiomark {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid var(--achromatic9);
}

.radiomark {
  border-radius: 100%;
}

.checkmark:after,
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

input:checked ~ .checkmark:after,
input:checked ~ .radiomark:after {
  display: block;
}

input:checked ~ .radiomark {
  border-color: var(--medium_blue1);
}

.contact_input {
  width: 280px !important;
}

.checkmark:after {
  left: 8px;
  top: 3px;
  width: 6px;
  height: 10px;
  transform: rotate(45deg);
  border-bottom: 2px solid var(--medium_blue1);
  border-right: 2px solid var(--medium_blue1);
}

.phone span,
.email span {
  color: var(--navy_blue8);
  margin-left: 10px;
}

input::placeholder {
  font-size: 1.5rem;
  color: var(--navy_blue8);
  padding-left: 10px;
}

span.red {
  color: #e50000;
  margin-left: 2px;
}

.radiomark:after {
  left: 4px;
  top: 4px;
  width: 15px;
  height: 15px;
  background: var(--medium_blue1);
  border-radius: 50%;
}

.personal_bg {
  background-color: var(--medium_blue10);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.contact_footer {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid var(--achromatic10);
  margin-top: 20px;
}

.contact_footer span {
  color: var(--medium_blue1);
}

.contact_footer .blue_btn {
  border-radius: 10px;
  padding: 20px 80px;
  margin-top: 30px;
}

.pagination > ul {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}

.pagination ul {
  display: flex;
}

.pagination ul li {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 5px;
  background-color: #fff;
  border-radius: 5px;
  filter: drop-shadow(0px 2px 4px rgba(0, 5, 121, 0.25));
  cursor: pointer;
}

.arrow_num {
  background-color: var(--medium_blue10) !important;
  transition: all 0.3s;
}

.arrow_num.active {
  color: #fff;
  background-color: var(--medium_blue1) !important;
}

.arrow_next img {
  transform: rotate(-180deg);
}

.kakao_map .contents1 {
  padding-top: 30px;
  text-align: center;
}

/* toast */
.Toastify__toast-container--top-right {
  top: 8em !important;
  width: 15% !important;
}
.Toastify__toast-body {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* expo */
.expo_page .content_title {
  height: 170px;
}
.expo_page .container {
  padding: 60px 0;
}

.expo_page .content_title h1 {
  transform: translate(-50%, -195%);
}

.expo_header {
  padding: 20px;
  text-align: center;
}

.expo_header .mobile_logo img {
  margin: 0;
}

.expo_search > form {
  display: flex;
  width: 100%;
  justify-content: center;
}

.expo_search input {
  width: 76%;
}

.expo_content {
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.expo_content1 {
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expo_page .content_bg {
  padding: 0;
  overflow: hidden;
  position: relative;
}

.expo_page .faq_list:nth-child(1) {
  margin-top: 0;
}

.selectbox {
  background-color: #fff !important;
  border-radius: 10px;
  border: 1px solid var(--cornflower_blue1);
  width: 120px;
  margin-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;
  background: url("../assets/img/selectbox.png") no-repeat 89% 50%/12px auto;
}

select::-ms-expand {
  display: none; /*for IE10,11*/
}

.expo_badge {
  border-radius: 100px;
  border: 1px solid var(--cornflower_blue1);
  color: var(--cornflower_blue1);
  margin-right: 15px;
  padding: 8px 15px;
  margin: 10px 10px 10px 0;
  transition: all 0.3s;
}

.expo_badge:hover {
  background-color: var(--cornflower_blue1);
  color: #fff;
}

.expo1 {
  position: relative;
  align-items: baseline;
}

.expo1::before {
  display: block;
  content: "";
  position: absolute;
  height: 1px;
  width: calc(100% - 40px);
  top: 0;
  background-color: #d2d4de;
}

.expo_p {
  flex-shrink: 0;
  align-items: center;
  display: flex;
  margin: 10px 20px 10px 0;
}

.expo_border {
  padding-left: 20px;
  border-left: 1px solid #d2d4de;
}

.expo_faq {
  position: relative;
}

/* rpa2 page */
.expo_faq .intro_bg1 {
  left: -130px;
  top: 0%;
}

.expo_faq .intro_bg2 {
  right: -10%;
  top: 5%;
}

.rpa2_iframe {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.rpa2_iframe:last-child {
  margin-bottom: 0px;
}

.rpa2_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 290px;
  margin: 0 15px;
  z-index: 99;
}

.expo_faq h2 {
  margin-bottom: 50px;
}

.rpa2_box p {
  margin-top: 10px;
}

.rpa2_box a {
  width: 290px;
}

.rpa2_box a img {
  width: 100%;
}

/* loading */
.loading_wrap {
  z-index: 99999;
  background-color: #fff;
  display: flex;
  width: 500px;
  height: 380px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  box-shadow: 0px 2px 30px rgba(0, 5, 121, 0.1);
  flex-direction: column;
}

.loading span {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 15px;
  border-radius: 50%;
  animation: loading 1s 0s linear infinite;
  margin-top: 35px;
}

.loading span:first-child {
  margin-left: 0px;
}

.loading_wrap p {
  margin-top: 25px;
  text-align: center;
}

.loading_wrap p span {
  color: #574fb1;
}

.loading span:nth-child(1) {
  animation-delay: 0s;
  background-color: #70a9ff;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
  background-color: #7370ff;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
  background-color: #af70ff;
}

@keyframes loading {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.bg_loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  opacity: 0.4;
  z-index: 9999;
}

@media screen and (max-width: 768px) {
  .faq_search {
    align-items: normal;
    flex-direction: column;
  }

  .faq_search input {
    height: 45px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .faq_search .blue_btn {
    justify-content: center;
  }

  .support_header p {
    margin-bottom: 20px;
  }

  .faq_title {
    padding: 17px;
  }

  .faq_title .sub_title4 {
    width: 90%;
  }

  .support_box {
    padding: 20px;
  }

  .contact_list li > p {
    margin: 20px 0 10px 0;
  }

  .course > label {
    margin-bottom: 20px;
    width: 35%;
  }
  
/* 
  .phone span,
  .email span {
    color: var(--navy_blue7);
  } */

  input[type="text"],
  input[type="email"],
  textarea {
    padding: 5px;
  }

  .personal_bg {
    padding: 15px;
    margin-bottom: 20px;
  }

  .contact_footer .blue_btn {
    justify-content: center;
  }

  .contact_wrap {
    margin: 50px 0;
  }

  .expo_search > form {
    flex-direction: column;
  }

  .expo_search input {
    width: 100%;
  }

  .Toastify__toast-container--top-right {
    top: 90px !important;
    width: 100% !important;
  }

  .Toastify__toast {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 420px) {
  .dropdown_icon,
  .dropup_icon {
    right: 20px;
  }
}
