.news_box {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(165, 169, 188, 0.5);
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news_box .news_title {
  margin: 0 auto;
  padding-bottom: 40px;
  text-align: center;
}

.news_box .news_content {
  display: block;
  -webkit-line-clamp: unset;
  width: 80%;
  margin: 0 auto;
}

.news_box .news_content p {
  width: 100%;
  padding-bottom: 50px;
}

.news_box .image img {
  max-width: 100%;
  padding: 10px 0;
  margin: 0 auto;
}

.news_box_badge {
  display: inline-flex;
  justify-content: center;
  padding-bottom: 30px;
}

.news_box_badge span {
  padding: 8px 15px;
  border-radius: 100px;
  border: 1px solid var(--medium_blue1);
  color: var(--medium_blue1);
}

.news_wrap_top {
  padding-top: 0px;
  margin-top: -50px;
}

.news_list_flex {
  justify-content: flex-start;
  margin-bottom: 20px;
}

.news_first {
  width: 98.35%;
  height: 225px;
  margin: 20px 10px;
  display: inline-flex;
}

.news_first .news_content {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-height: 1.6;
}

.news_first .news_thumbnail {
  height: 100%;
}

.news_first .news_title {
  margin: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700 !important;
}

.news_img {
  width: 300px;
  height: 225px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
}

.news_img img {
  width: 100%;
}

.news_detail_wrap .contact_footer {
  padding: 0px;
  margin: 0px;
  border: none;
}

.news_btn {
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: space-between;
  border-top: 1px solid var(--achromatic10);
  margin-top: 40px;
}

.news_btn .btn_prev,
.news_btn .btn_next {
  background-color: var(--navy_blue10);
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 140px;
}

.news_btn .btn_prev img {
  transform: rotate(180deg);
}

.news_btn .btn_list {
  width: 140px;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  background-color: var(--navy_blue3);
}

.news_page_detail_wrap .content_title h2 {
  font-size: 2rem;
  font-weight: 400 !important;
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

@media screen and (max-width: 1200px) {
  .news_first {
    display: none;
  }

  .news_wrap_item {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .news_wrap {
    text-align: center;
  }

  .news_detail_wrap .blue_btn {
    justify-content: center;
  }

  .news_box {
    padding: 20px;
  }

  .news_box .news_content {
    width: 90%;
  }

  .news_box .news_title {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    padding-bottom: 20px;
  }

  .news_btn .btn_prev,
  .news_btn .btn_next {
    width: 100px;
    padding: 10px 0;
  }

  .news_btn .btn_list {
    margin: 0 15px;
  }
}
