:root {
  --medium_blue1: #0557f5;
  --medium_blue8: #b4cdfc;
  --medium_blue10: #e6eefe;
  --cornflower_blue1: #4d54ff;
  --cornflower_blue3: #7076ff;
  --cornflower_blue6: #a6a9ff;
  --cornflower_blue9: #dbddff;
  --cornflower_blue10: #edeeff;
  --navy_blue1: #1d2958;
  --navy_blue2: #343e69;
  --navy_blue3: #5d6587;
  --navy_blue4: #61698a;
  --navy_blue7: #a5a9bc;
  --navy_blue8: #bbbfcd;
  --navy_blue10: #e8eaee;
  --achromatic1: #000000;
  --achromatic4: #4d4d4d;
  --achromatic5: #666666;
  --achromatic8: #b3b3b3;
  --achromatic9: #ccc;
  --achromatic10: #e6e6e6;
  --line-height: 1.4;
}
