.eureka_wrap .content_title h2.heading1 {
  top: 82% !important;
}

.portal_wrap .content_title .heading1 {
  top: 72%;
}

.portal_intro {
  text-align: center;
}

.portal_intro > .heading1 {
  padding-bottom: 0 !important;
}

.portal_intro_img {
  margin: 30px 0 190px 0;
  display: flex;
}

.portal_intro_img::after {
  position: absolute;
  left: 50%;
  content: "";
  background-color: var(--cornflower_blue9);
  width: 360px;
  height: 360px;
  border-radius: 1000px;
  transform: translateX(-50%);
}

.portal_intro_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  position: relative;
  z-index: 1;
  left: 50%;
  top: 75px;
}

.portal_intro_item {
  background-color: #fff;
  border-radius: 20px;
  height: 210px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-left: 30px;
}

.portal_intro_item:first-child {
  margin-left: 0;
}

.portal_intro_item .sub_title3 {
  padding-bottom: 0 !important;
}

.portal_intro_text .heading2,
.portal_intro_text .sub_title4 {
  width: 70%;
  padding-bottom: 0 !important;
  margin: 30px auto 25px;
  word-break: keep-all;
}

.portal_intro > .blue_btn {
  border-radius: 20px;
  width: 960px;
  padding: 25px;
  margin-top: 50px;
}

.quotes img {
  padding-left: 0;
  margin-bottom: 50px;
}

.quotes_left img {
  padding-right: 25px;
}

.quotes_right img {
  padding-left: 25px;
}

.portal_wrap .sec2 {
  margin: 100px 0;
}

.portal_wrap .container .heading1 {
  padding-bottom: 40px;
  text-align: center;
}

.portal_wrap .heading2 {
  padding-bottom: 28px;
  text-align: center;
}

.sec2_box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.sec2_box_wrap {
  margin-bottom: 100px;
}

.sec2_box:first-child {
  margin-top: 0px;
}

.sec2_box .box {
  width: 400px;
  height: 280px;
  margin-right: 20px;
  background: #fff;
  border: 1px solid var(--cornflower-blue-5, #9498ff);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sec2_box .box:nth-child(3),
.sec2_box .box:last-child {
  margin-right: 0;
}

.sec2_box .box .num {
  width: 50px;
  height: 50px;
  background: var(--cornflower-blue-3, #7076ff);
  border-radius: 100px;
  line-height: 50px;
  text-align: center;
  color: #fff;
}

.sec2_box .box img {
  padding-top: 30px;
  height: 80px;
}

.sec2_box .box p {
  padding-top: 30px;
  text-align: center;
}

.left table {
  width: 100%;
  border-top: 3px solid #7076ff;
  margin-bottom: 50px;
  text-align: left;
}

.left table thead tr {
  height: 60px;
  background: var(--cornflower-blue-10, #edeeff);
}

.left table thead th {
  padding-left: 3rem;
  border-left: 1px solid #bbbfcd;
  vertical-align: middle;
}

.left table tbody tr {
  height: 70px;
  border-bottom: 1px solid #bbbfcd;
}

.left table tbody tr:first-child {
  height: 120px;
}

.left table tbody tr th {
  width: 200px;
  padding-left: 3rem;
  vertical-align: middle;
}

.left table tbody tr td {
  width: 550px;
  padding-left: 3rem;
  border-left: 1px solid #bbbfcd;
  vertical-align: middle;
}

span.dot {
  width: 10px;
  height: 10px;
  background: var(--cornflower-blue-3, #7076ff);
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

span.dot2 {
  width: 6px;
  height: 6px;
  background: var(--cornflower-blue-1, #4d54ff);
  border-radius: 1px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.portal_wrap .sub_title3 {
  padding-bottom: 20px;
}

.aa_box {
  height: 80px;
  background: var(--cornflower-blue-10, #edeeff);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
}
.aa_box div p {
  float: left;
  padding-right: 35px;
  margin-bottom: 0px;
}

.aa_box p.t_right {
  color: var(--cornflower-blue-1, #4d54ff);
  float: right;
  margin-bottom: 0px;
}

.sec3 {
  padding: 100px 0 90px;
  background: var(--medium-blue-10, #e6eefe);
  word-break: keep-all;
  word-wrap: normal;
}

.sec3 .contents1 {
  font-size: 1.7rem;
}

.sec3 .d-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.sec3 .box {
  margin: 10px;
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 30px 0px rgba(0, 5, 121, 0.1);
}

.sec3 .header_box {
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  background: var(--cornflower-blue-3, #7076ff);
  color: #fff;
  margin-bottom: 30px;
}

.sec3 .text {
  color: var(--achromatic-3, #333);
  opacity: 0.5;
  border-top: 1px solid #5256c2;
  margin-top: 30px;
  padding-top: 30px;
}

.portal_wrap .intro_video h2 {
  padding-bottom: 0;
}

/* EurekaGenAI */
.pt100 {
  padding-top: 100px;
}

.text_left {
  text-align: left !important;
}

.chat_box {
  padding: 100px 0;
}

.chat_box .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.chat_box .container .heading1,
.chat_box .container .heading2 {
  padding-bottom: 20px;
}

.LLM_ex_box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* .LLM_ex_box .LLMex1 {
  width: 200px;
  margin-bottom: 30px;
} */

.LLM_ex_box .LLMex2 {
  width: 100%;
}

.chat_box_left {
  width: 70%;
}

.LLM_icon_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LLM_icon_wrap .portal_intro_item {
  padding: 10px 20px 20px;
  margin: 10px;
}

.portal_intro_item img {
  height: 90px;
}

.LLM_icon_wrap img {
  height: auto;
}

.LLM_icon_wrap p {
  width: 80%;
  height: 62px;
  margin-top: 20px;
  text-align: center;
}

.chat_box_right img {
  width: 220px;
  margin-left: 20px;
}

@media (max-width: 1000px) {
  .portal_intro > .blue_btn {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .sec3 .d-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .sec3 .contents1 {
    font-size: 1.8rem;
  }

  .portal_wrap .intro_video .news_bg {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .portal_intro {
    text-align-last: left;
  }

  .portal_wrap .container .heading1 {
    padding-bottom: 25px;
  }

  .portal_intro .heading1 {
    display: flex;
    justify-content: center;
  }

  .portal_intro_img {
    display: block;
    margin: 25px 0 0 0;
  }

  .portal_intro_wrap {
    flex-direction: column;
    transform: translateX(0);
    left: auto;
    top: auto;
  }

  .portal_intro_img::after {
    display: none;
  }

  .portal_intro_item {
    margin-left: 0;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 10px;
    margin-bottom: 15px;
    justify-content: flex-start;
  }

  .portal_intro_item:nth-child(2) img {
    padding: 0 4px;
  }

  .portal_intro_item:last-child img {
    padding: 0 11px;
  }

  .portal_intro_text .heading2,
  .portal_intro_text .sub_title4 {
    width: 100%;
    margin: 15px 0 30px 0;
    word-break: keep-all;
    word-wrap: break-word;
    text-align: left;
  }

  .portal_intro > .blue_btn {
    width: 100%;
    margin-top: 25px;
    padding: 20px;
    text-align: left;
    word-break: keep-all;
    word-wrap: break-word;
  }

  .portal_intro > .blue_btn > .sub_title2 br {
    display: none;
  }

  .sec3 {
    padding: 50px 0;
  }

  .sec3 .box {
    margin: 0 0 15px 0;
  }

  .sec3 .d-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .sec3 .header_box {
    margin-bottom: 20px;
  }

  .sec3 .text {
    margin-top: 20px;
    padding-top: 20px;
    opacity: 1;
  }

  .banner_wrap .banner_cont {
    align-items: flex-start;
  }

  /* Eureka Chat */
  .pt100 {
    padding-top: 50px;
  }

  .chat_intro_img img {
    width: 50px;
    height: auto;
    margin: 0 30px 0 20px;
    padding: 0 !important;
  }

  /* .LLM_ex_box .LLMex1 {
    width: 150px;
    margin-bottom: 20px;
  } */

  .LLM_icon_wrap {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }

  .LLM_icon_wrap .portal_intro_item img {
    height: 70px;
    margin-right: 20px;
  }

  .LLM_icon_wrap .portal_intro_item:nth-child(2) img,
  .LLM_icon_wrap .portal_intro_item:last-child img {
    padding: 0;
  }

  .LLM_icon_wrap .portal_intro_item:last-child img {
    width: 70px;
  }

  .LLM_icon_wrap .sub_title3 {
    margin-top: 0;
    width: 240px;
    display: flex;
    align-items: center;
    text-align: left;
  }

  .chat_box_right img {
    width: 160px;
    margin-left: 20px;
  }
}

@media (max-width: 480px) {
  .quotes img {
    display: none;
  }

  .portal_wrap .sec2 {
    margin: 50px 0;
  }

  .sec2_box {
    display: block;
  }

  .sec2_box_wrap {
    margin-bottom: 50px;
  }

  .sec2_box:last-child {
    margin-top: 0;
  }

  .sec2_box .box {
    width: 100%;
    height: 120px;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 10px 0 20px;
    margin-bottom: 10px;
  }

  .sec2_box .box img {
    padding: 0 20px;
    height: 50px;
  }

  .sec2_box .box p {
    padding-top: 0;
    text-align: left;
    width: 50%;
  }

  .left table thead th,
  .left table tbody tr th,
  .left table tbody tr td {
    padding-left: 1rem;
  }

  .aa_box {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    margin-top: 20px;
  }

  .aa_box > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--cornflower_blue6);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .aa_box div p {
    padding-right: 0;
  }

  span.dot {
    width: 5px;
    height: 5px;
  }

  /* Eureka Chat */
  /* .LLM_ex_box .LLMex1 {
    width: 100px;
    margin-bottom: 20px;
  } */

  .chat_box .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .chat_box_left {
    width: 90%;
  }

  .chat_box {
    padding: 50px 0;
  }

  .banner_wrap .blue_btn1 {
    width: 100%;
    justify-content: space-between;
  }

  .chat_box_right img {
    width: 200px;
    margin-bottom: 50px;
    margin-left: 0px;
  }
}
