.banner_wrap {
  background-color: var(--navy_blue10);
  padding: 10px 20px;
}

.banner_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #d2d4de;
}

.banner_cont:last-child {
  border-bottom: none;
}

.banner_cont > div {
  display: flex;
  align-items: center;
}

.banner_cont p {
  padding-left: 10px;
}

.banner_wrap .blue_btn {
  margin-top: 0 !important;
}

@media screen and (max-width: 1280px) {
  .banner_wrap {
    padding: 0;
  }

  .banner_wrap .banner_cont div {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .banner_wrap .banner_cont {
    flex-direction: column;
  }

  .banner_wrap .banner_cont div {
    padding-bottom: 15px;
  }

  .banner_wrap .banner_cont div {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .banner_wrap .banner_cont a {
    width: 100%;
  }
}
