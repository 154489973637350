.content_title {
  position: relative;
  overflow: hidden;
  height: 316px;
  background: linear-gradient(
    93.79deg,
    rgba(36, 105, 168, 0.5) 18.03%,
    rgba(116, 79, 164, 0.5) 93.11%
  );
}

.content_title img {
  position: relative;
  z-index: -1;
}

.content_title h1 {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;
  transform: translate(-50%, -100%);
  color: #fff;
}

.content_title h2 {
  position: absolute;
  top: calc(50% + 90px);
  left: 50%;
  transform: translate(-50%, -100%);
  color: #fff;
}

@media screen and (max-width: 767px) {
  .content_title {
    height: 250px;
  }

  .content_title h1 {
    font-size: 30px;
    text-align: center;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .content_title h2 {
    font-size: 26px;
    text-align: center;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
