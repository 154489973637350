.ai_solution_page .content_title h1 {
  top: 72%;
}

.ai_solution_page .content_bg {
  padding: 50px 0 100px;
}

.ai_solution_page .text_box {
  padding-left: 40px;
}

.ai_solution_page .page_box {
  margin-top: 30px;
}

.ai_solution_page .page_box:first-child {
  margin-top: 0px;
}

.ai_solution_page .page_box .icon_img {
  width: 270px;
}

.ai_solution_page .text_box .contents1 {
  padding: 5px 30px 30px 0;
  word-break: keep-all;
}

.ai_solution_page .blue_btn {
  margin-right: 20px;
  word-break: keep-all;
}

@media screen and (max-width: 885px) {
  .ai_solution_page .blue_btn {
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .ai_solution_page .text_box {
    padding: 20px;
  }
  .page_box {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
  }

  .ai_solution_page .text_box .contents1 {
    padding: 5px 0 30px 0;
  }
}