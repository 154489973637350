.intro_wrap {
  word-break: keep-all;
}

.space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.space_evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btn_margin {
  margin-right: 10px;
}

.content_bg .cont1 .heading3 {
  padding: 10px 0 30px;
}

.rpa_page .content_title .heading1 {
  top: 72%;
}

.cont1_div {
  width: 64%;
}

.rpa_page .content_bg .cont2 {
  padding: 80px 0px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rpa_page .content_bg .cont2 div {
  display: flex;
}

.rpa_page .content_bg .cont2 .column {
  flex-direction: column;
}

.rpa_page .content_bg .cont2 .box {
  width: 260px;
  height: 260px;
  background: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 30px rgba(0, 5, 121, 0.1);
  border-radius: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rpa_page .content_bg .cont2 .box_margin {
  margin: 0 1vw;
}

.rpa_page .content_bg .cont2 .sub_title2 {
  padding: 30px 0 5px;
}

.section2 {
  padding: 100px 0;
}

.section2 .heading1 {
  text-align: center;
  padding-bottom: 70px;
}

.section2 .box {
  width: 300px;
  height: 300px;
  border-right: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section2 .box:last-child {
  border-right: none;
}

.section2 .box p {
  padding: 40px 0 12px;
}

.section2 span {
  padding: 10px 20px;
  border: 1px solid #82abfa;
  border-radius: 100px;
  color: var(--medium_blue1);
}

.section3 {
  padding: 100px 0;
  background-color: var(--medium_blue10);
}

.section3 .heading3 {
  padding: 20px 0 30px;
}
.section3 .cont2 {
  padding-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.section3 .cont2 .box {
  display: flex;
  align-items: center;
  padding: 17px 15px;
}

.section3 .cont2 .img_box {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cornflower_blue3);
}

.section3 .cont2 .text_box {
  padding-left: 20px;
}

.page_box .text_box .contents1 {
  padding: 5px 0 30px;
}

@media screen and (max-width: 1280px) {
  .rpa_page .content_bg .cont1 img {
    width: 30vw;
    margin-left: 2vw;
  }
  .rpa_page .content_bg .cont2 .box {
    width: 19vw;
    height: 20vw;
  }

  .rpa_page .content_bg .cont2 img.plus_icon {
    width: 8vw;
  }
  .section3 .cont1 img {
    width: 30vw;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 1023px) {
  .rpa_page .content_bg .cont1 div {
    width: 500px;
  }
  .rpa_page .content_bg .cont2 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .rpa_page .content_bg .cont2 .box {
    width: 25vw;
    height: 25vw;
  }
  .rpa_page .content_bg .cont2 .box_margin {
    margin: 0 2vw;
  }
  .rpa_page .content_bg .cont2 img.plus_icon {
    width: 100px;
  }

  .section2 .box {
    width: 22vw;
    height: 30vw;
  }

  .section2 .box img {
    width: 18vw;
  }
}

@media screen and (max-width: 825px) {
  .btn_margin {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .rpa_page .content_bg .cont1 img {
    display: none;
  }

  .rpa_page .content_bg .cont2 {
    padding: 80px 0 0;
  }

  .rpa_page .content_bg .cont2 div {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .rpa_page .content_bg .cont2 .column {
    padding-left: 25px;
  }

  .rpa_page .content_bg .cont2 .box {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: row;
    padding: 20px;
  }

  .rpa_page .content_bg .cont2 .box_margin {
    margin: 2vw 0;
  }

  .rpa_page .content_bg .cont2 .sub_title2 {
    padding: 0 0 5px;
  }

  .section2 {
    padding: 50px 0;
  }

  .section2 .heading1 {
    padding-bottom: 25px;
  }

  .section2 .space_between {
    flex-direction: column;
  }

  .section2 .space_between .box {
    width: 100%;
    height: 280px;
    border: none;
  }

  .section2 .box img {
    width: auto;
  }

  .section2 .box p {
    padding: 20px 0 12px;
  }

  .section3 .cont1 img {
    display: none;
  }

  .section3 {
    padding: 50px 0;
  }

  .section3 .cont1 .sub_title4 {
    padding-bottom: 30px;
    border-bottom: 1px solid #b4cdfc;
  }

  .section3 .cont2 {
    padding-top: 30px;
    grid-template-columns: 1fr;
  }

  .section3 .cont2 .sub_title4 {
    padding-bottom: 5px;
  }

  .section3 .cont2 .box {
    padding: 15px 0;
  }

  .section3 .cont2 .img_box img {
    width: 20px;
    height: 20px;
  }

  .section3 .cont2 .text_box {
    width: 85%;
  }

  .btn_margin {
    margin-bottom: 10px;
  }
}
