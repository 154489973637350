.intro_wrap .content_title {
  height: 100%;
}

.intro_wrap .content_title .heading1 {
  top: calc(50% + 40px);
}

.header_tab {
  width: 100%;
  background-color: #1d295887;
  height: 68px;
  position: absolute;
  top: 318px;
}

.header_tab .container {
  display: flex;
  height: 100%;
  align-items: center;
}

.header_tab .intro,
.header_tab .support {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_tab .header_active {
  color: #fff;
  background: linear-gradient(90deg, #4c54ff 41.27%, #4c94ff 92.02%);
}

.header_tab .support,
.header_tab .intro {
  background-color: var(--navy_blue10);
}

.intro_visual {
  background: #fff;
}

.intro_visual .container {
  display: flex;
  justify-content: space-evenly;
}

.visual_textbox p {
  margin: 30px 0 50px 0;
}

.intro_bg {
  position: absolute;
  opacity: 0.2;
}

.intro_bg1 {
  left: -130px;
  top: 10%;
}

.intro_bg2 {
  right: -4%;
  bottom: 7%;
}

.intro_video {
  overflow: hidden;
  padding: 100px 0 90px 0;
  text-align: center;
  position: relative;
}

.intro_video h2 {
  margin-bottom: 50px;
}

.intro_advantage {
  background-color: var(--medium_blue10);
  padding: 100px 0;
}

.intro_advantage .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.advantage_wrap {
  margin-top: 30px;
}

.advantage_wrap li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.advantage_wrap li:last-child {
  margin-bottom: 0;
}

.advantage_wrap .wrap_bg {
  background-color: var(--cornflower_blue3);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 20px;
}

.intro_rpa {
  padding: 100px 0;
  text-align: center;
}

.intro_rpa > p {
  margin-top: 20px;
}

.intro_rpa .rpa_wrap {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 70px 0;
}

.rpa_wrap li span {
  color: var(--navy_blue7);
}

.solution {
  background-color: var(--cornflower_blue10);
  padding: 100px 0;
  text-align: center;
}

.solution_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.solution_textbox {
  text-align: left;
}

.solution_textbox > p {
  margin: 10px 0 40px 0;
}

.solution_wrap li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.solution_wrap li:last-child {
  margin-bottom: 70px;
}

.solution_wrap li span {
  width: 120px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--cornflower_blue1);
  color: var(--cornflower_blue1);
  margin-right: 15px;
}

@media screen and (max-width: 1280px) {
  .intro_visual .container > img,
  .intro_bg,
  .intro_advantage .container > img,
  .solution_container > img {
    display: none;
  }

  .solution_wrap li span {
    flex: 1;
  }
  .solution_wrap li p {
    flex: 2;
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .intro_wrap .content_title {
    height: 325px;
  }
  .intro_wrap .content_title .heading1 {
    top: calc(38% + 40px);
    left: 21%;
    transform: translate(-20%, -18%);
  }
  .header_tab {
    height: 55px;
    top: 270px;
  }
  .header_tab .container {
    width: 100%;
  }

  .visual_textbox p {
    margin: 30px 0 25px 0;
  }

  .intro_video,
  .intro_advantage,
  .intro_rpa,
  .solution {
    padding: 50px 0;
  }

  .intro_video h2,
  .solution_wrap li:last-child,
  .rpa_wrap li,
  .solution_textbox > p {
    margin-bottom: 25px;
  }

  .intro_rpa .rpa_wrap {
    margin: 40px 0 20px;
  }

  .advantage_textbox > h2,
  .solution_textbox {
    text-align: center;
  }

  .wrap_text {
    width: 80%;
  }
}
