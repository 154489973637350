.client_bg {
  background: var(
    --gradation-3,
    linear-gradient(174deg, #fff 0%, #dfdff8 100%)
  );
}

.client_wrap {
  position: relative;
  padding: 100px 0;
  text-align: center;
}

.client_wrap .hand_icon {
  width: 4%;
  padding-right: 10px;
}

.client_wrap .button1 {
  color: #0557f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client_wrap .heading3 {
  padding: 25px 0;
}

.client_wrap .link {
  width: 90%;
  height: 70px;
  margin: 30px 5%;
  position: absolute;
  cursor: pointer;
  z-index: 999999;
}

.client_wrap img {
  width: 100%;
}

.client_wrap .slick-slider {
  margin: 40px 10px;
}

.client_wrap .slick-list {
  width: 90%;
  margin: 0 auto;
}

.client_wrap .slick-prev,
.client_wrap .slick-next {
  width: 22px !important;
  height: 40px !important;
  cursor: pointer;
}

.client_wrap .slick-prev {
  background-image: url(../assets/img/slick_prev.png) !important;
  background-size: 22px 40px !important;
  background-repeat: no-repeat !important;
  left: 0 !important;
}

.client_wrap .slick-next {
  background-image: url(../assets/img/slick_next.png) !important;
  background-size: 22px 40px !important;
  background-repeat: no-repeat !important;
  right: 0 !important;
}

.client_wrap .slick-prev:before,
.client_wrap .slick-next:before {
  font-size: 0 !important;
  color: transparent !important;
  opacity: 0 !important;
}

@media screen and (max-width: 1280px) {
  .client_wrap {
    padding: 70px 0;
  }
}

@media screen and (max-width: 768px) {
  .client_wrap {
    padding: 50px 0;
  }
  .client_wrap .hand_icon{
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .client_wrap {
    padding: 20px 0;
  }

  .client_wrap .slick-prev {
    left: -10px !important;
  }
  .client_wrap .slick-next {
    right: -10px !important;
  }
}
