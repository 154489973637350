* {
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  font-family: var(--pretentard);
  color: var(--achromatic1);
}

.container {
  width: 1280px;
  margin: 0 auto;
}

.d-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.blue_btn,
.blue_btn1 {
  background: linear-gradient(90deg, #4c54ff 41.27%, #4c94ff 92.02%);
}
.outline_btn {
  background: linear-gradient(92.85deg, #c58aff 5.38%, #3ce0eb 91.73%),
    linear-gradient(0deg, #19131e, #19131e);
}

.blue_btn img,
.blue_btn1 img,
.outline_btn img {
  padding-left: 30px;
}

.blue_btn,
.outline_btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  border-radius: 100px;
  color: #fff;
  transition: all 0.5s;
}

.blue_btn1 {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-radius: 100px;
  color: #fff;
  transition: all 0.5s;
}

.blue_btn:hover,
.blue_btn1:hover,
.outline_btn:hover {
  box-shadow: 0px 4px 10px rgba(0, 5, 121, 0.5);
}

.outline_btn:hover {
  background: #fff;
}

.outline_btn:hover span {
  background: linear-gradient(92.85deg, #c58aff 5.38%, #3ce0eb 91.73%),
    linear-gradient(0deg, #19131e, #19131e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.iframe {
  position: relative;
  width: 80%;
  margin: 50px auto 0;
  padding-bottom: 56.25%; /* 16:9 비율 */
  height: 0;
}

.iframe iframe {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
}

.content_bg {
  background: linear-gradient(143.71deg, #ffffff 14.32%, #dfdff8 89.02%);
  padding: 100px 0;
}

.page_box {
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 30px rgba(0, 5, 121, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 20px;
}

/* 버튼 비활성화 */
.blue_btn:disabled,
.blue_btn1:disabled,
.outline_btn:disabled {
  cursor: auto;
  background: #004de8;
  opacity: 0.3;
}

.blue_btn:disabled:hover,
.blue_btn1:disabled:hover,
.outline_btn:disabled:hover {
  box-shadow: none;
}

/* 오른쪽 뉴스레터 */
.side_box {
  position: fixed;
  z-index: 999;
  top: 40%;
  right: 20px;
  width: 70px;
  padding: 15px 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--navy-blue-10, #e8eaee);
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 5, 121, 0.25);
  transition: 0.3s;
}

.side_box:hover {
  box-shadow: 0px 3px 6px 2px rgba(0, 5, 121, 0.25);
}

.side_wrap {
  cursor: pointer;
}

.side_wrap .newsletter,
.side_wrap .go_top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.side_wrap .newsletter {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d2d4de;
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 50%;
  }

  .container {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .blue_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 10px;
  }

  .iframe {
    margin: 30px auto 0;
  }

  .side_box {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .iframe {
    width: 100%;
    padding-bottom: 62.25%;
    margin: 25px auto;
  }

  .iframe iframe {
    height: 90%;
  }

  .content_bg {
    padding: 50px 0 !important;
  }
}
