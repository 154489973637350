.news_container {
  overflow: hidden;
  position: relative;
  padding-bottom: 100px;
  background: linear-gradient(143.71deg, #ffffff 14.32%, #dfdff8 89.02%);
}

.news_bg {
  position: absolute;
  opacity: 0.2;
}
.news_bg1 {
  left: -6.5%;
  right: 91%;
  top: 8%;
  bottom: 91%;
}
.news_bg2 {
  right: -8.5%;
  bottom: 8%;
}

.news_wrap {
  position: relative;
  z-index: 1;
  text-align: left;
  padding-top: 100px;
}

.title {
  text-align: center;
}

.title img {
  margin-right: 15px;
}

.news_list {
  display: flex;
  justify-content: center;
  color: var(--achromatic4);
  margin: 20px 0 40px 0;
}

.news_list li {
  padding: 0px 10px 10px 10px;
  margin: 0 10px 10px 10px;
  cursor: pointer;
}

.news_list li::after {
  content: "";
  display: inline-block;
  background-color: #ccced5;
  width: 1px;
  height: 14px;
  transform: translateX(20px);
}

.news_list li.last::after {
  display: none;
}

.news_active {
  color: var(--medium_blue1);
  border-bottom: 3px solid var(--medium_blue1);
}

.news {
  width: 300px;
  height: 385px;
  display: inline-block;
  box-shadow: 0px 0px 10px rgba(165, 169, 188, 0.5);
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  margin: 10px;
}

.news img {
  /* width: 300px;
  height: 300px; */
  transition: all 0.5s;
}

.news_thumbnail {
  width: 100%;
  height: 200px;
  background: #fff;
  padding: 24px 28px;
  transition: all 0.5s;
}

.news:hover .news_thumbnail {
  background: linear-gradient(90deg, #4c54ff 41.27%, #4c94ff 92.02%);
}

.news:hover .news_img img {
  transform: scale(1.1);
}

.news:hover .news_badge {
  background: none;
  border-color: #fff;
  color: #fff;
}

.news:hover .news_title,
.news:hover .news_content {
  color: #fff !important;
}

.news_badge {
  display: inline-flex;
  border-radius: 100px;
  border: 1px solid transparent;
  background: linear-gradient(#fff, #fff),
    linear-gradient(90deg, #4c54ff 41.27%, #4c94ff 92.02%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: var(--medium_blue1);
  margin-bottom: 15px;
}

.news_badge span {
  padding: 8px 15px;
  font-size: 1.4rem;
}

.news_badge:hover {
  border: 1px solid #fff;
  background: transparent;
}

.news_badge:hover span {
  color: #fff;
}

.news_title {
  width: 100%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news_detail_wrap .content_title h2 {
  font-size: 1.9rem;
  font-weight: 400 !important;
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

.news_content {
  color: var(--achromatic5);
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: none;
  /* display: -webkit-box; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news_wrap .blue_btn {
  margin-top: 40px;
}

.m_news_list_wrap {
  display: none;
}

@media screen and (max-width: 620px) {
  .news {
    height: 370px;
  }

  .news_wrap {
    padding-top: 50px;
  }

  .news_container {
    padding-bottom: 70px;
  }

  .news_wrap .blue_btn {
    margin-top: 20px;
  }

  .m_news_list_wrap {
    display: block;
  }

  .news_list {
    display: none;
  }

  .m_news_list {
    transform: translateY(-6px);
  }

  .m_news_select {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    top: 19px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 2px solid var(--medium_blue1);
  }

  .m_news_select p {
    width: 100%;
    color: var(--medium_blue1);
  }

  .title .m_select_icon {
    margin-right: 0;
    position: relative;
  }

  .m_news_list li {
    background-color: var(--medium_blue10);
    padding: 15px 0;
    border-top: 1px solid var(--medium_blue8);
  }

  .m_news_list .first {
    border-top: none;
  }

  .news_bg {
    display: none;
  }
}
