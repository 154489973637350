.route {
  border-bottom: 1px solid var(--achromatic10);
}

.route ul {
  justify-content: flex-end;
  display: flex;
  color: var(--achromatic8);
}

.route li {
  margin-left: 15px;
  padding: 22px 0;
  display: inline-flex;
  align-items: center;
  cursor: default;
  justify-content: center;
}

.route li:first-child {
  margin-left: 0;
}

.route li::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #d2d4de;
  border-right: 2px solid #d2d4de;
  transform: rotate(45deg);
}

.route li:last-child::after {
  display: none;
}

@media screen and (max-width: 1023px) {
  .route ul {
    justify-content: flex-start;
  }
}
