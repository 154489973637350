.content_bg.fff {
  background: #fff;
  padding-top: 50px;
}

.heading1 {
  text-align: center;
}

.newsletter_wrap {
  font-size: 14px;
}

.newsletter_wrap > .d-start {
  margin: 40px 10px 20px;
}

/* table */
.option_color {
  padding: 0 4px;
  color: #00aec6;
}

.SelectBoxRow {
  margin-left: 20px;
  padding: 10px 25px 10px 10px;
  border-radius: 5px;
  border: 1px solid #e0e2e6;
}

select {
  appearance: none;
  background: url(../assets/img/select_arrow.png) right no-repeat;
  background-position-x: 40px;
}

.newsletter_table {
  width: 100%;
  font-size: 16px;
}

.newsletter_table thead {
  background: #f5f5f7;
}

.newsletter_table thead tr th {
  font-weight: 600 !important;
}

.newsletter_table thead tr th:first-child {
  width: 100px;
}

.newsletter_table thead tr th:last-child {
  width: 200px;
}

.newsletter_table thead tr th,
.newsletter_table tbody tr th {
  padding: 15px;
  font-weight: 500;
  text-align: center;
}

.newsletter_table tbody tr {
  cursor: pointer;
  border-top: 1px solid #e0e2e6;
  border-bottom: 1px solid #e0e2e6;
}

.newsletter_table tbody tr:hover {
  background: #e6eefe;
}

.newsletter_table tbody tr th:nth-child(2) {
  text-align: left;
  padding-left: 50px;
}

/* pagination */
.pagination {
  margin-top: 50px;
}

ul.pagination li a,
ul.pagination li.active a {
  font-size: 14px;
}

/* detail */
.newsletter_detail iframe {
  width: 100%;
  height: 600px;
}

.newsletter_detail_wrap h2 {
  text-align: center;
  color: #000;
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .newsletter_table tbody tr th:nth-child(2) {
    min-width: 170px;
    padding-left: 20px;
  }
}
