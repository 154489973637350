.footer_wrap {
  background-color: var(--navy_blue1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_wrap .container {
  padding: 40px 0px;
}

.footer_wrap > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address img {
  padding-bottom: 15px;
}

.address p {
  color: #fff;
  padding-bottom: 5px;
}

.address p.copy {
  opacity: 50%;
  padding: 10px 0 0;
}

.footer_wrap .link {
  color: #fff;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 60%;
}

.footer_wrap .link a,
.footer_news {
  width: 55px;
  height: 55px;
  padding: 10px;
  margin: 5px 0px 5px 10px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}

.footer_wrap .link a > div {
  display: flex;
  align-items: center;
}

.footer_wrap .link a:hover,
.footer_news:hover {
  background-color: var(--navy_blue4);
  box-shadow: 0px 4px 10px rgba(0, 5, 121, 0.5);
  transition: all 0.2s;
}

.footer_wrap .footer_news .side_wrap .newsletter {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.footer_wrap .footer_blog .side_wrap .newsletter {
  border-bottom: none;
}

.footer_wrap .side_wrap .newsletter img {
  margin-top: 0;
}

@media screen and (max-width: 1280px) {
  .footer_wrap .address {
    width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .footer_wrap .container {
    display: flex;
    flex-direction: column;
    padding: 35px 0;
  }
  .footer_wrap .address {
    width: 100%;
  }
  .address img {
    padding-top: 30px;
  }
  .footer_wrap .link {
    width: 100%;
    justify-content: center;
    order: -1;
  }
}
