.case_page {
  text-align: center;
}

.case_page .content_bg {
  padding: 50px 0 100px;
}

.case_page .contact_footer {
  padding: 0px;
  margin: 0px;
  border: none;
}

.case_page .blue_btn {
  justify-content: center;
}

.case_page .content_title h2 {
  font-size: 2rem;
  font-weight: 400 !important;
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

@media screen and (max-width: 1280px) {
  .example_wrap .ex_list li:nth-of-type(n + 7) {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .example_wrap .ex_list li:nth-of-type(n + 5) {
    display: block;
  }
}

@media screen and (max-width: 420px) {
  .example_wrap .blue_btn {
    width: 300px;
    margin: 0 auto;
  }

  .content_title h2 {
    top: 74%;
  }
}
