/** Noto Sans(Google Fonts) */
/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;700;800;900&display=swap"); */

:root {
  --pretentard: "Pretendard-Regular";
  /* --noto-sans: -apple-system, "Noto Sans KR", sans-serif; */
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

a,
h1,
h2,
h3,
h4,
button {
  font-family: var(--pretentard);
}

/* 공통 font */
.display1 {
  font-size: 5rem;
  font-weight: 900 !important;
  line-height: var(--line-height);
}

.display2 {
  font-size: 5rem;
  font-weight: 800 !important;
  line-height: var(--line-height);
}

.heading1 {
  font-size: 4rem;
  font-weight: 600 !important;
  line-height: var(--line-height);
}

.heading2 {
  font-size: 3rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.heading3 {
  font-size: 2.8rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.sub_title1 {
  font-size: 2.6rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.sub_title2 {
  font-size: 2.4rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.sub_title3 {
  font-size: 2.2rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.sub_title4 {
  font-size: 2rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.contents1 {
  font-size: 1.8rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.contents2 {
  font-size: 1.6rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.contents3 {
  font-size: 1.4rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
  letter-spacing: 0.05em;
}

.contents4 {
  font-size: 1.4rem;
  font-weight: 500 !important;
  line-height: var(--line-height);
}

.button1 {
  font-size: 1.8rem;
  font-weight: 700 !important;
  line-height: var(--line-height);
}

.button2 {
  font-size: 1.6rem;
  font-weight: 600 !important;
  line-height: var(--line-height);
}
