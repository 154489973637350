.popup {
  display: flex;
  position: fixed;
  top: 15%;
  right: 10%;
  z-index: 99;
}

.popup .relative {
  position: relative;
  margin-left: 10px;
  box-shadow: 10px 35px 20px 0px rgb(0 0 0 / 50%);
}

.popup img {
  max-width: 400px;
}

.popup .link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.popup .close-timeLimit {
  position: absolute;
  left: 0;
  margin-top: -2px;
  padding: 5px 10px;
  background: #333;
  color: #fff;
  display: block;
  width: 100%;
  text-align: left;
}

.popup .close {
  position: absolute;
  top: -6px;
  right: 2px;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.7);
  width: 40px;
  height: 40px;
  z-index: 9999;
}

.popup .hide-24-hours {
  background: #333;
  width: 100%;
  height: 24px;
  margin-top: -2px;
}

.popup .hide-24-hours button {
  padding: 4px 10px;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .popup {
    display: none;
    /* display: flex;
    flex-direction: column;
    width: 90vw;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999; */
  }
  /* 
  .popup img {
    max-width: 90vw;
  } */
}
