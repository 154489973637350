.slider_container {
  position: relative;
  width: 100%;
  display: flex;
  /* background: #839cf4; */
  backdrop-filter: blur(28px);
  padding: 100px 0;
  overflow: hidden;
  word-break: keep-all;
}

.slider_container > img {
  position: absolute;
  width: 140vw;
  height: 110vh;
  transform: translate(-20%, -25%);
  z-index: -1;
}

.slider_container .blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  backdrop-filter: blur(2.5px);
}

.slider_text {
  position: relative;
  top: 50%;
  text-align: center;
  color: #fff;
}

.slider_title {
  width: 80%;
  margin: 0px auto 50px;
}

.slider_sub_title1 {
  width: 80%;
  margin: 0 auto 50px;
}

.outline_btn {
  transition: none;
  width: 300px;
  height: 65px;
}

.outline_btn:hover {
  width: 300px;
  height: 65px;
  box-sizing: border-box;
  /* border: 2px solid transparent; */
  padding: 4px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(to right, #c58aff, #3ce0eb);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.outline_btn:hover span {
  background: linear-gradient(92.85deg, #c58aff 5.38%, #3ce0eb 91.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.outline_btn:hover img {
  background-image: url(../assets/img/button_arrow_icon_gray.png);
  background-repeat: no-repeat;
  background-position-x: right;
}

/* 슬라이드 부분 */
.slider_container .slick-slider {
  top: 50%;
  transform: translateY(-50%);
  /* margin: 0 10px; */
}

.slider_container .slick-dots {
  bottom: -55px;
}

.slider_container .slick-dots li.slick-active button:before {
  /* margin-top: 40px; */
  color: #9f53ff;
  opacity: 1;
}

.slider_container .slick-dots li button:before {
  /* margin-top: 40px; */
  color: #d2d4de;
  opacity: 1;
}

.slider_container .slick-prev,
.slider_container .slick-next {
  width: 22px !important;
  height: 40px !important;
  cursor: pointer;
  z-index: 1;
}

.slider_container .slick-prev {
  background-image: url(../assets/img/slick_prev_white.png) !important;
  background-size: 22px 40px !important;
  background-repeat: no-repeat !important;
  left: 0 !important;
}
.slider_container .slick-next {
  background-image: url(../assets/img/slick_next_white.png) !important;
  background-size: 22px 40px !important;
  background-repeat: no-repeat !important;
  right: 0 !important;
}

.slider_container .slick-prev:before,
.slider_container .slick-next:before {
  font-size: 0 !important;
  color: transparent !important;
  opacity: 0 !important;
}

@media screen and (max-width: 1280px) {
  .slider_container {
    padding: 70px 0;
  }

  .slider_container .slick-dots {
    bottom: -45px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }

  .slider_sub_title1 {
    margin: 0 auto 50px;
  }

  .slider_container > img {
    width: auto;
    height: 110vh;
    transform: translate(-30%, -20%);
  }

  .slider_container .slick-dots {
    bottom: -35px;
  }
}

@media screen and (max-width: 420px) {
  .slider_title {
    margin: 0 auto 30px;
  }

  .slider_sub_title1 {
    margin: 0 auto 30px;
  }
}
